// store.ts

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import errorSlice, { ErrorState } from "./alert/alert";
import authReducer, { AuthState } from "./auth";
export interface RootState {
  auth: AuthState;
  error: ErrorState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  error: errorSlice,

  // Add other reducers here if needed
});
const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
